import { useEffect, useRef, useContext } from "react";
import { useDisclosure } from "@nextui-org/react";
import { Element } from "react-scroll";
import { ReactSortable } from "react-sortablejs";
import PropTypes from "prop-types";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import DeleteIcon from "@mui/icons-material/Delete";
import useGcsFileUpload, {
  deserializeFileData,
  deserializeImageData,
  serializeExistingFileData,
  serializeNewFileData,
} from "hooks/useGcsFileUpload";
import { briefPropTypes } from "features/brief";
import { UserContext, AddUserInfoModal } from "features/user";
import { submissionPropTypes, useSubmissionMutations } from "features/submission";
import { ConfirmationModal, ModalClickContainer, Button, Dropdown } from "ui";
import {
  FormFilePreviewImage,
  FormFile,
  FormMultiFile,
  useValidateForm,
  Input,
  Textarea,
  Form,
} from "forms";
import { enterBriefSchema } from "utils/validation/validationSchemas";
import { stringifyFormData, writeFilesToInput } from "utils/helpers";
import { enterBriefPolicies } from "./policyData";

const IMAGEMAXSIZE = 26214400;

export default function EnterBriefForm({
  brief,
  submission,
  onPublish = () => {},
  onSave = () => {},
}) {
  const { user } = useContext(UserContext);
  const imageInputRef = useRef(null);
  const formRef = useRef(null);
  const technicalFilesRef = useRef([]);
  const thumbnailRef = useRef(null);
  const {
    files: images,
    dispatch,
    handleFileUpload: handleImageFileUpload,
    handleCancelFileUpload: cancelImageFileUpload,
  } = useGcsFileUpload("image", IMAGEMAXSIZE);
  const { createSubmission, updateSubmission } = useSubmissionMutations();
  const { handleSubmit, errors, loading } = useValidateForm({
    validationSchema: enterBriefSchema,
    errorPage: "enter_brief",
  });
  const {
    isOpen: isUserInfoModalOpen,
    onOpen: openUserInfoModal,
    onClose: closeUserInfoModal,
  } = useDisclosure();

  useEffect(() => {
    if (submission.images) {
      dispatch({
        payload: deserializeImageData(submission.images.sort((a, b) => a.order_id - b.order_id)),
      });
    }
  }, [submission]);

  useEffect(() => writeFilesToInput(images, imageInputRef.current), [images]);

  const serializeFormData = (formData) => {
    formData.new_files = serializeNewFileData(
      images
        .filter((f) => f.isNewFile)
        .concat(technicalFilesRef.current.filter((f) => f.isNewFile)),
    );
    formData.existing_files = serializeExistingFileData(
      images
        .filter((f) => !f.isNewFile)
        .concat(technicalFilesRef.current.filter((f) => !f.isNewFile)),
    );
    formData.type = brief.status === "proposing" ? "proposal" : "submission";

    delete formData.thumbnailUrl;
    delete formData.publishedImages;
    delete formData.publishedTechnical_file;
    delete formData.require_working;
    delete formData.images;
    delete formData.technical_file; // We use by reference instead of formData
    delete formData.addUserInfo;

    return formData;
  };

  const handleCreateOrUpdateSubmission = async (formData) => {
    if (formData.addUserInfo) {
      openUserInfoModal();
      return {};
    }

    formData = serializeFormData(formData);
    const data = stringifyFormData(formData, ["thumbnail"]);

    try {
      const res = submission.id
        ? await updateSubmission.mutateAsync({ id: submission.id, data })
        : await createSubmission.mutateAsync({ briefId: brief.id, data });

      if (formData.published) onPublish(res.data);
      else onSave(res.data);

      return {};
    } catch (error) {
      return {
        errors: {
          ...error.parseError(formData),
          api: error.details,
        },
      };
    }
  };

  const handleParseFormData = (formData, published = false, addUserInfo = false) => {
    formData.addUserInfo = addUserInfo;
    formData.published = published || (submission.published ?? false);
    formData.require_working = brief.require_working;
    formData.thumbnailUrl = thumbnailRef.current ?? submission.thumbnail;
    formData.publishedImages = images;
    formData.publishedTechnical_file = technicalFilesRef.current;
    return formData;
  };

  return (
    <>
      <Form
        className="gap-8"
        onSubmit={handleSubmit({
          onSubmit: handleCreateOrUpdateSubmission,
          parseData: handleParseFormData,
        })}
        validated={false}
        noValidate
        autoComplete="off"
        ref={formRef}
      >
        <Input
          name="title"
          id="title"
          label="Title"
          size="lg"
          placeholder="Enter your title"
          labelPlacement="outside"
          maxLength={50}
          defaultValue={submission.title ?? ""}
          isInvalid={!!errors.title}
          errorMessage={errors.title}
          classNames={{
            label: "uppercase font-bold",
          }}
        />

        <div>
          <Element name="thumbnailUrl" />
          <FormFilePreviewImage
            name="thumbnail"
            onUpdate={(src) => {
              thumbnailRef.current = src;
            }}
            defaultValue={submission?.thumbnail}
            isInvalid={!!errors.thumbnail || !!errors.thumbnailUrl}
            errorMessage={errors.thumbnail || errors.thumbnailUrl}
          />
        </div>

        <div>
          <span className="uppercase old-mb-2 old-d-block font-bold old-form-label">
            Body Images (max 10)
          </span>

          <ReactSortable
            list={images}
            setList={(newImages) =>
              dispatch({
                payload: newImages.map(({ chosen, ...image }) => image),
              })
            }
            animation={200}
            className={
              images.length > 0
                ? "old-rounded-4 old-border old-border-secondary old-border-style-dashed old-bg-offwhite old-mb-3 old-overflow-hidden"
                : "old-d-none"
            }
          >
            {images.length > 0 &&
              images.map((image, index) => (
                <div
                  key={image.file_id}
                  className={`old-w-100 old-d-flex old-border-secondary ${errors[image.file_id] ? "old-bg-danger old-text-white" : ""} ${index + 1 < images.length ? "old-border-bottom" : ""}`}
                >
                  <div className="old-d-flex old-align-items-center old-px-1">
                    <DragIndicatorIcon />
                  </div>
                  <div className="old-position-relative old-w-100">
                    <Element name={String(image.file_id)} />
                    <img
                      src={image.url}
                      className={`old-w-100 old-border old-border-offwhite ${index === 0 ? "old-rounded-top-4" : ""} ${index + 1 === images.length ? "old-rounded-bottom-4" : ""}`}
                      data-testid="enter-brief-image"
                      alt={`${image.name} upload`}
                      data-role="form-error-component"
                    />

                    {errors[image.file_id] && (
                      <span className="old-py-3 old-d-flex old-align-items-center">
                        <ErrorOutlineIcon className="old-me-2" />
                        {errors[image.file_id]}
                      </span>
                    )}

                    <div className="old-position-absolute old-top-0 old-start-0 old-mt-3 old-ms-3">
                      <Dropdown data-testid="enter-brief-image-options-dropdown">
                        <Dropdown.DropdownTrigger onClick={(e) => e.preventDefault()}>
                          <Button
                            isIconOnly
                            color="primary"
                            data-testid="enter-brief-image-options-button"
                          >
                            <SettingsOutlinedIcon />
                          </Button>
                        </Dropdown.DropdownTrigger>

                        <Dropdown.DropdownMenu>
                          <Dropdown.DropdownSection
                            showDivider
                            classNames={{
                              group: "p-0 m-0",
                              divider: "list-none",
                            }}
                          >
                            <Dropdown.DropdownItem
                              key="up"
                              onClick={() =>
                                dispatch({
                                  type: "REORDER",
                                  payload: {
                                    prevIndex: index,
                                    nextIndex: index - 1,
                                  },
                                })
                              }
                              endContent={<ArrowCircleUpIcon />}
                            >
                              Move Up
                            </Dropdown.DropdownItem>

                            <Dropdown.DropdownItem
                              key="down"
                              onClick={() =>
                                dispatch({
                                  type: "REORDER",
                                  payload: {
                                    prevIndex: index,
                                    nextIndex: index + 1,
                                  },
                                })
                              }
                              endContent={<ArrowCircleDownIcon />}
                            >
                              Move Down
                            </Dropdown.DropdownItem>
                          </Dropdown.DropdownSection>

                          <Dropdown.DropdownItem
                            key="delete"
                            onClick={() =>
                              dispatch({
                                type: "REMOVE",
                                payload: image.file_id,
                              })
                            }
                            endContent={<DeleteIcon />}
                            color="danger"
                            className="text-danger"
                          >
                            Delete
                          </Dropdown.DropdownItem>
                        </Dropdown.DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              ))}
          </ReactSortable>

          <Element name="publishedImages" />
          <FormFile.Dropzone
            name="images"
            className="bg-content2 hover:bg-focus rounded-3xl old-p-md-6"
            onUpdate={handleImageFileUpload}
            onCancel={cancelImageFileUpload}
            errorMessage={errors.images || errors.publishedImages}
            isInvalid={!!errors.images || !!errors.publishedImages}
            maxSize={IMAGEMAXSIZE}
            ref={imageInputRef}
          />
        </div>

        <Textarea
          name="description"
          label="Description"
          id="description"
          labelPlacement="outside"
          placeholder="You have 600 characters to tell the world about your idea. Describe your work and tell everyone about your inspiration too."
          size="lg"
          defaultValue={submission.description}
          maxLength={600}
          minRows={10}
          isInvalid={!!errors.description}
          errorMessage={errors.description}
          classNames={{
            label: "uppercase text-base font-bold",
          }}
        />

        <div>
          <Element name="publishedTechnical_file" />
          <FormMultiFile
            name="technical_file"
            maxFiles={1}
            fileType="technical"
            label="Upload Working File (.AI/.PSD/.TIFF/.EPS/.OBJ)"
            fileTypes={[]} // TODO: do we want to define a type here
            ref={technicalFilesRef}
            key={
              submission.files ? `technical${submission.files.map((f) => f.file.id)}` : "technical"
            }
            errorMessage={errors.technical_file || errors.publishedTechnical_file}
            isInvalid={!!errors.technical_file || !!errors.publishedTechnical_file}
            fileError={(fileId) => errors[fileId]}
            defaultValue={
              submission.files
                ? deserializeFileData(submission.files).filter((f) => f.type === "technical")
                : []
            }
            required={
              submission.files &&
              deserializeFileData(submission.files).filter((f) => f.type === "technical").length > 0
                ? true
                : brief.require_working
            }
          />
        </div>

        <div className="old-pt-3" data-testid="enter-brief-policies">
          <h6 className="uppercase font-bold">
            By Continuing you are agreeing to the following terms:
          </h6>
          <ul>
            {enterBriefPolicies.map((policy) => {
              const agreement = policy.agreement(brief);
              if (!agreement) return null;

              return (
                <li key={policy.name}>
                  <small className="font-roman">{agreement}</small>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="old-d-flex old-align-items-center old-justify-content-end">
          {!submission?.published ? (
            <div className="flex gap-2">
              <Button
                variant="faded"
                type="submit"
                size="lg"
                data-testid="enter-brief-draft"
                isLoading={loading}
                aria-label="draft"
                trackingName="enter brief"
              >
                Save Draft
              </Button>

              <ModalClickContainer
                modal={ConfirmationModal}
                protectedAction
                modalProps={{
                  promptText:
                    "Are you sure want to publish this submission? Published submissions will be entered into the brief.",
                  confirmText: "Publish",
                  action: (e) =>
                    handleSubmit({
                      formRef,
                      onSubmit: handleCreateOrUpdateSubmission,
                      parseData: (formData) =>
                        handleParseFormData(
                          formData,
                          true,
                          !(user.dob && user.country_code && user.gender),
                        ),
                    })(e),
                }}
                as={Button}
                color="primary"
                size="lg"
                isLoading={loading}
                data-testid="enter-brief-submit"
                trackingName="enter brief"
                aria-label="publish"
              >
                Publish
              </ModalClickContainer>
            </div>
          ) : (
            <Button
              type="submit"
              color="primary"
              size="lg"
              data-testid="enter-brief-submit"
              aria-label="update"
              isLoading={loading}
              trackingName="enter brief"
            >
              Save Changes
            </Button>
          )}
        </div>
      </Form>

      <AddUserInfoModal
        title="Just one more step!"
        show={isUserInfoModalOpen}
        onSuccess={() => {
          handleSubmit({
            formRef,
            onSubmit: handleCreateOrUpdateSubmission,
            parseData: (formData) => handleParseFormData(formData, true),
          })(new Event(""));
          closeUserInfoModal();
        }}
        onHide={closeUserInfoModal}
        footerProps={{
          confirmText: "Finish!",
          cancelText: "skip",
          onCancel: (e) => {
            handleSubmit({
              formRef,
              onSubmit: handleCreateOrUpdateSubmission,
              parseData: (formData) => handleParseFormData(formData, true),
            })(e);
            closeUserInfoModal();
          },
        }}
      />
    </>
  );
}
EnterBriefForm.propTypes = {
  brief: briefPropTypes.isRequired,
  submission: PropTypes.oneOfType([submissionPropTypes, PropTypes.shape({})]).isRequired,
  onPublish: PropTypes.func,
  onSave: PropTypes.func,
};
