import { useMemo, useContext } from "react";
import { useNavigate, useLocation, generatePath } from "react-router-dom";
import { submissionListPropTypes } from "features/submission/schemas/submissionSchema";
import { UserContext } from "features/user";
import { DocumentMetaContext } from "features/navigation";
import { ImageCard, User, Chip } from "ui";
import routes from "default/routes";
import PropTypes from "prop-types";
import SubmissionOptionsDropdown from "features/submission/components/dropdown/SubmissionOptionsDropdown";
import SubmissionBadge from "features/submission/components/badge/SubmissionBadge";

const submissionBadgeContent = (submission, isOrg = false) => ({
  get proposal() {
    if (!submission.published || submission.brief.status === "proposing") return null;
    if (submission.brief.status === "selecting" && (!isOrg || (isOrg && !submission.is_winner)))
      return "pending";

    if (!submission.is_winner) return "failed";

    return "approved";
  },
  get submission() {
    if (!submission.published || submission.brief.status !== "completed" || !submission.is_winner)
      return null;
    return "winner";
  },
});

function Overlay({ submission, ...optionsProps }) {
  const { user } = useContext(UserContext);
  const submissionBadgeType = useMemo(
    () => submissionBadgeContent(submission, user.organisation?.id)[submission.type],
    [submission],
  );

  return (
    <div className="old-hover-overlay old-w-100 old-h-100">
      <div className="old-position-absolute old-h-100 old-top-0 old-w-100 old-ps-3 old-pt-3 old-overlay-fade-top old-d-inline-block">
        <User
          name={submission.user.username}
          avatarProps={{
            src: submission.user.icon,
          }}
          badges={submission.user.badges}
          classNames={{
            base: "bg-transparent gap-2 p-0",
            name: "text-xl",
          }}
        />
      </div>

      {submissionBadgeType && (
        <div className="old-position-absolute old-top-0 old-w-100 old-mt-n1 old-ms-1 old-pe-3 old-pt-3 old-d-flex old-justify-content-end">
          <SubmissionBadge type={submissionBadgeType} />
        </div>
      )}

      <div className="old-position-absolute old-bottom-0 old-d-flex old-flex-wrap old-align-items-center old-justify-content-end old-w-100 old-px-3 old-gap-2 old-pb-3">
        <div className="w-full flex justify-end gap-2">
          {!submission.published && (
            <Chip
              classNames={{
                base: "bg-purple text-primary-foreground",
                dot: "bg-content1",
              }}
              variant="dot"
            >
              Draft
            </Chip>
          )}
          {submission.type === "proposal" && (
            <Chip
              classNames={{
                base: "bg-purple text-primary-foreground",
                dot: "bg-content1",
              }}
              variant="dot"
            >
              Proposal
            </Chip>
          )}
        </div>

        <div className="old-d-flex old-align-items-center old-mw-75 old-gap-2">
          <span className="old-ml-auto old-text-black old-bg-white old-p-1 old-px-2 old-rounded-pill old-shadow old-text-truncate">
            {submission.title || `"${submission.brief.title}" Submission`}
          </span>
        </div>
        <SubmissionOptionsDropdown submission={submission} {...optionsProps} />
      </div>
    </div>
  );
}

Overlay.propTypes = {
  submission: submissionListPropTypes.isRequired,
};

export default function SubmissionCard({
  entry: submission,
  onClick = undefined,
  blank = false,
  ...overlayProps
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const documentMetaContext = useContext(DocumentMetaContext);

  const navigateOnClick = () => {
    if (onClick) {
      onClick(submission);
      return;
    }

    const current = documentMetaContext?.currentRoute || "voting";
    navigate(
      {
        pathname: generatePath(routes[`${current}Submission`], {
          username: submission.user.username,
          submissionId: submission.id,
        }),
        search: location.search,
      },
      {
        state: { scrollToTop: false },
      },
    );
  };

  return (
    <ImageCard
      name="submission"
      blank={!submission || blank}
      image={submission?.thumbnail || ""}
      onClick={navigateOnClick}
      overlay={Overlay}
      overlayProps={{
        submission,
        ...overlayProps,
      }}
    />
  );
}

SubmissionCard.propTypes = {
  entry: submissionListPropTypes.isRequired,
  onClick: PropTypes.func,
  blank: PropTypes.bool,
};
