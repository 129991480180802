import { useState } from "react";
import { Element } from "react-scroll";
import { InView } from "react-intersection-observer";
import { useParams, useNavigate, Link, generatePath } from "react-router-dom";
import {
  BriefStatusModal,
  briefPropTypes,
  briefStatusContent,
  useEnterBriefMutations,
} from "features/brief";
import {
  Container,
  SectionLink,
  Row,
  Col,
  ScrollPopup,
  StickyActionButton,
  StickyActionShareButton,
  ModalClickContainer,
  Alert,
  Button,
} from "ui";
import { useProtectedAction } from "features/authentication";
import { ErrorToast } from "features/report";
import { toast } from "react-toastify";
import routes from "default/routes";
import PrivateBriefNDAModal from "features/brief/components/modal/PrivateBriefNDAModal";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import DOMpurify from "dompurify";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import Currency from "utils/currency";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

// You can change the properties and order of the brief here.
const defaultSections = {
  brief: { inView: true, title: "The Brief", attribute: "description" },
  consider: {
    inView: false,
    title: "Things to Consider",
    attribute: "things_to_consider",
  },
  files: { inView: false, title: "Helpful Resources", attribute: "files" },
  criteria: {
    inView: false,
    title: "Selection Criteria",
    attribute: "selection_criteria",
  },
  incentives: {
    inView: false,
    title: "Incentives",
    attribute: "other_incentives",
  },
};

const fileType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  order_id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  file: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
});

function BriefFiles({ files }) {
  return (
    <div className="old-all-mb-3">
      {files
        .sort((a, b) => a.order_id - b.order_id)
        .map((file) => (
          <a
            download
            href={file.file.url}
            className="old-bg-offwhite old-rounded-4 old-p-3 capitalize old-d-flex old-justify-content-between old-align-items-center"
            key={file.id}
          >
            {file.description || `${file.type} File`}

            <Button color="success" size="sm">
              Download
            </Button>
          </a>
        ))}
    </div>
  );
}
BriefFiles.propTypes = {
  files: PropTypes.arrayOf(fileType).isRequired,
};

function BriefAttribute({ attribute, title, name, setInView, status }) {
  if (!attribute || (Array.isArray(attribute) && !attribute.length)) return null;

  return (
    <InView
      as="div"
      className="border-none old-border-top old-border-2 old-border-lightgrey old-mb-6 old-mb-lg-section-margin"
      onChange={(inView) => setInView(name, inView)}
      threshold={0.2}
    >
      <div className="old-mw-brief-text old-mt-4">
        <Element name={name} />
        <small className="uppercase font-bold old-text-lightgrey old-mb-4 old-d-block">
          {title}
        </small>

        {name === "files" ? (
          <BriefFiles files={attribute} />
        ) : (
          <>
            <Markdown className="old-markdown" rehypePlugins={[rehypeRaw]}>
              {DOMpurify.sanitize(attribute)}
            </Markdown>
            {name === "incentives" && status === "completed" && (
              <Alert className="font-roman text-sm">
                The live competition for this brief has ended, and all rewards have now been given
                to the winners. New submissions can still earn votes and be spotlighted!
              </Alert>
            )}
          </>
        )}
      </div>
    </InView>
  );
}
BriefAttribute.propTypes = {
  attribute: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(fileType)]).isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setInView: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

function BriefDateAttribute({ title, now, attribute = "", last = false }) {
  if (!attribute || attribute === "") return null;
  const completed = now >= dayjs(attribute);

  return (
    <div className="old-d-flex old-position-relative" data-testid="brief-details">
      <div className="old-d-flex old-flex-column old-align-items-center old-me-3">
        <span
          className={`old-rounded-circle !size-4 old-d-block old-bg-${completed ? "black" : "lightgrey"}`}
        />

        {!last && (
          <span
            className={`old-border old-flex-grow-1 old-my-2 old-border-${completed ? "black" : "lightgrey"}`}
          />
        )}
      </div>
      <div>
        <h6 className="uppercase font-bold">{title}:</h6>
        <p className="old-fs-14">{dayjs(attribute).format("MMM. DD, HH:mm")}</p>
      </div>
    </div>
  );
}
BriefDateAttribute.propTypes = {
  attribute: PropTypes.string,
  title: PropTypes.string.isRequired,
  now: PropTypes.number.isRequired,
  last: PropTypes.bool,
};

export default function BriefDetails({ brief }) {
  const { id } = useParams();
  const [sections, setSections] = useState(defaultSections);
  const [showNDAModal, setShowNDAModal] = useState(false);
  const { callToAction } = briefStatusContent(brief)[brief.status];
  const now = Date.now();
  const navigate = useNavigate();
  const {
    enterBrief: { mutate, isPending: enterBriefLoading },
  } = useEnterBriefMutations();
  const redirectToPath = generatePath(routes.brief, { id });
  const isLocked =
    !brief.entered && ["scheduled", "submitting", "completed", "proposing"].includes(brief.status);

  const [enterBrief] = useProtectedAction(
    (briefUrlId) => {
      mutate(
        { id: briefUrlId },
        {
          onSuccess: (_, { id: briefId }) => {
            if (briefId !== id) {
              navigate(redirectToPath, {
                replace: true,
                preventScrollReset: true,
              });
            }
          },
          onError: (error) => {
            toast(
              <ErrorToast
                errorMessage={error.details.message}
                errorProps={{
                  defaultReason: "issue",
                  defaultPage: "enter_brief",
                  apiError: error.details,
                }}
              />,
              {
                limit: 1,
              },
            );
          },
        },
      );
    },
    {
      actionName: "enterBrief",
      redirectToPath,
    },
  );

  const handleEnterOrShowNDA = () => {
    if (brief.type === "private") {
      setShowNDAModal(true);
      return;
    }

    enterBrief(id);
  };

  const setSectionInView = (section, inView) => {
    setSections({
      ...sections,
      [section]: { ...sections[section], inView },
    });
  };

  return (
    <div className="bg-content1 old-rounded-top-4 old-position-relative old-sb-6">
      <Container className="old-contain-paint old-pt-6 old-rounded-top-4 old-mt-n6 old-bg-white old-text-dark">
        <Row gap={6}>
          <Col
            xs={12}
            lg={4}
            className={`old-order-lg-first old-order-last ${!brief.entered ? "old-mt-l-4 old-mt-lg-0" : ""}`}
          >
            <div className="old-position-initial old-position-lg-sticky old-top-section-margin old-mb-6 old-fs-18 old-fs-md-20">
              <div className="old-d-none old-d-lg-block border-none old-border-top old-border-2 old-border-lightgrey">
                <span className="uppercase font-bold old-text-lightgrey old-mt-4 old-mb-4 old-d-block old-fs-14">
                  Sections
                </span>

                <ul className="font-bold uppercase old-mb-5 old-mb-md-6">
                  {Object.keys(sections).map((section) => {
                    const sectionsInView = Object.keys(sections).filter((s) => sections[s].inView);
                    const inView = sectionsInView[sectionsInView.length - 1] === section;
                    const attribute = brief[sections[section].attribute];

                    // Don't show the link if the attribute doesnt exist
                    if (!attribute || (Array.isArray(attribute) && !attribute.length)) return null;

                    return (
                      <SectionLink
                        key={section}
                        name={section}
                        title={sections[section].title}
                        inView={inView}
                      />
                    );
                  })}
                </ul>
              </div>

              {brief.status !== "completed" && (
                <div className="border-none old-border-top old-border-2 old-border-lightgrey">
                  <span className="uppercase font-bold old-text-lightgrey old-my-4 old-d-block old-fs-14">
                    Key Dates
                  </span>
                  <ModalClickContainer
                    modal={BriefStatusModal}
                    modalProps={{
                      brief,
                    }}
                  >
                    <div className="old-px-4 pt-4 old-pb-3 old-bg-offwhite old-rounded-4 old-position-relative old-mb-3">
                      <div className="flex items-top justify-between mb-3">
                        <span className="text-sm text-default-400 uppercase mt-1">
                          Times are displayed in your browser&apos;s local time zone
                        </span>
                        <HelpOutlineIcon className="!size-8" />
                      </div>
                      <div className="mt-4">
                        <BriefDateAttribute attribute={brief.start_time} title="Launch" now={now} />
                        <BriefDateAttribute
                          attribute={brief.proposal_deadline}
                          title="Proposal Deadline"
                          now={now}
                        />
                        <BriefDateAttribute
                          attribute={brief.proposal_selected}
                          title="Proposal Selected"
                          now={now}
                        />
                        <BriefDateAttribute
                          attribute={brief.submission_deadline}
                          title="Submission Deadline"
                          now={now}
                        />
                        <BriefDateAttribute
                          attribute={brief.voting_deadline}
                          title="Voting Deadline"
                          now={now}
                        />
                        <BriefDateAttribute
                          attribute={brief.winners_selected}
                          title="Winner Announcement"
                          now={now}
                          last
                        />
                      </div>
                    </div>
                  </ModalClickContainer>
                </div>
              )}

              {callToAction && brief.entered && (
                <Button
                  color="primary"
                  size="lg"
                  as={Link}
                  to={callToAction.path}
                  trackingName={callToAction.trackingName}
                  fullWidth
                >
                  {callToAction.text}
                </Button>
              )}
            </div>
          </Col>

          <Col
            xs={12}
            lg={7}
            className={`old-position-relative ${isLocked ? "old-blur-background old-blur-background-3" : ""}`}
          >
            {Object.keys(sections).map((section, index) => {
              if ((index >= 2 && !brief.entered) || !("attribute" in sections[section]))
                return null;

              return (
                <BriefAttribute
                  name={section}
                  title={sections[section].title}
                  attribute={brief[sections[section].attribute]}
                  key={section}
                  setInView={setSectionInView}
                  status={brief.status}
                />
              );
            })}

            {brief?.prize_money && brief.prize_money > 0 && (
              <div className="border-none old-border-top old-border-2 old-border-lightgrey old-mb-6 old-mb-md-section-margin">
                <div className="old-mw-brief-text old-mt-4">
                  <small className="uppercase font-bold old-text-lightgrey old-mb-3 old-d-block">
                    Prize money
                  </small>
                  <h2>
                    {Currency.get(brief.currency).format(brief.prize_money)} {brief.currency}
                  </h2>
                </div>
                {brief.status === "completed" && (
                  <Alert className="font-roman text-sm">
                    The live competition for this brief has ended, and all prize money has been
                    given to the winners.
                  </Alert>
                )}
              </div>
            )}

            {isLocked && (
              <div className="old-position-absolute old-top-0 old-mt-l-4 old-start-0 old-h-100 old-w-sm-100 old-bg-transparent old-text-dark text-center old-z-1 old-px-3 old-px-md-0">
                <p className="old-pt-4 border-none old-border-top old-border-2 old-border-lightgrey">
                  You must register your interest to view the rest of the details and enter the
                  brief.
                </p>

                <Button
                  color="primary"
                  size="lg"
                  trackingName="brief get started"
                  data-testid="enter-brief"
                  onClick={() => handleEnterOrShowNDA(brief.id)}
                  isLoading={enterBriefLoading}
                >
                  {enterBriefLoading ? "Unlocking Details" : "Unlock Details"}
                </Button>
              </div>
            )}
          </Col>

          <Col lg={1} className="old-d-none old-d-lg-block">
            <div className="sticky old-top-section-margin old-pt-4 old-mb-6 old-pb-3 old-mb-lg-section-margin text-center">
              <StickyActionButton
                icon={FeedOutlinedIcon}
                tooltip={brief.organisation.name}
                color="primary"
                title={
                  <>
                    <span className="old-d-block old-mt-2">Organisation</span>
                    <span className="old-d-block">Profile</span>
                  </>
                }
                onClick={() =>
                  navigate(
                    generatePath(routes.organisation, {
                      id: brief.organisation.id,
                    }),
                  )
                }
              />

              <StickyActionShareButton
                title=""
                url={window.location.pathname}
                trackingLocation="brief actions"
                color="primary"
                shareButtonProps={{
                  color: "default",
                  className: "bg-content1",
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>

      {callToAction && brief.entered && (
        <ScrollPopup>
          <Button
            color="primary"
            as={Link}
            to={callToAction.path}
            trackingName={callToAction.trackingName}
            fullWidth
          >
            {callToAction.text}
          </Button>
        </ScrollPopup>
      )}

      <PrivateBriefNDAModal
        brief={brief}
        onConfirm={() => {
          enterBrief(id);
          setShowNDAModal(false);
        }}
        onHide={() => setShowNDAModal(false)}
        show={showNDAModal}
      />
    </div>
  );
}
BriefDetails.propTypes = {
  brief: briefPropTypes.isRequired,
};
