import { useState, forwardRef, useCallback, useEffect } from "react";
import { Button } from "ui";
import { parseDate, parseDateTime } from "@internationalized/date";
import { motion } from "framer-motion";
import { twJoin } from "tailwind-merge";
import DatePicker, { CalendarContainer } from "react-datepicker";
import dayjs from "dayjs";
import CalendarIcon from "icons/CalenderIcon";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PropTypes from "prop-types";
import DateInput from "forms/DateInput";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// Get a list of all years between two dates
const getYears = (startDate, endDate) => {
  const currentYear = endDate.getFullYear();
  const years = [];
  let startYear = startDate.getFullYear();
  while (startYear <= currentYear) {
    years.push(startYear);
    startYear += 1;
  }

  return years.reverse();
};

const parsedValue = (value, isDateTimePicker) => {
  if (!value) return null;
  if (isDateTimePicker) return parseDateTime(dayjs(value).format("YYYY-MM-DDTHH:mm:ss"));
  return parseDate(dayjs(value).format("YYYY-MM-DD"));
};

const CustomInput = forwardRef(
  ({ value, onUpdate, onClick, granularity, isDateTimePicker, inputRef, ...props }, ref) => {
    const [date, setDate] = useState(parsedValue(value, isDateTimePicker));

    useEffect(() => {
      if (value) setDate(parsedValue(value, isDateTimePicker));
    }, [value]);

    return (
      <div className="old-d-flex old-align-items-center old-position-relative">
        {/* NextUI does not scroll to datepickers with passed refs, so custom input with ref to scroll to is required */}
        <input ref={inputRef} className="absolute h-0" />
        <DateInput
          {...props}
          value={date}
          onChange={(v) => {
            setDate(v);

            // Only update when its a valid date
            if (v && v.year.toString().length >= 4) {
              onUpdate(
                new Date(
                  v.year,
                  v.month - 1,
                  v.day,
                  isDateTimePicker && v.hour,
                  isDateTimePicker && v.minute,
                  isDateTimePicker && v.second,
                ),
              );
            } else {
              onUpdate(null);
            }
          }}
          granularity={granularity}
          onFocus={() => {}}
          ref={ref}
          endContent={
            <Button isIconOnly onClick={onClick} size="sm" className="bg-transparent">
              <CalendarIcon className="text-xl text-default-400 pointer-events-none flex-shrink-0" />
            </Button>
          }
        />
      </div>
    );
  },
);
CustomInput.propTypes = {
  value: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  inputRef: PropTypes.func,
  granularity: PropTypes.string,
  isDateTimePicker: PropTypes.bool,
};
CustomInput.defaultProps = {
  inputRef: undefined,
  granularity: undefined,
  isDateTimePicker: false,
};

function CustomContainer({ className, children }) {
  return (
    <motion.div
      data-testid="datepicker"
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.15 }}
    >
      <CalendarContainer
        className={twJoin(
          "!rounded-3xl !border-none py-1 !bg-content1 shadow-lg !flex !overflow-hidden",
          className,
        )}
      >
        {children}
      </CalendarContainer>
    </motion.div>
  );
}
CustomContainer.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const DatePickerInput = forwardRef(
  ({ name, defaultValue, onChange, granularity, minDate, maxDate, ...inputProps }, ref) => {
    const [state, setState] = useState(defaultValue ? new Date(defaultValue) : "");
    const isDateTimePicker = ["hour", "minute", "second"].includes(granularity);

    const handleOnChange = (newDate) => {
      setState(newDate);
      if (onChange) onChange(newDate);
    };

    const CustomHeader = useCallback(
      ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="flex justify-center items-center mb-2 gap-1 px-1">
          <Button
            isIconOnly
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            className="min-w-7 w-7 h-7 text-default-400"
            variant="light"
          >
            <KeyboardArrowLeftIcon />
          </Button>

          <select
            value={months[dayjs(date).month()]}
            onChange={(e) => changeMonth(months.indexOf(e.target.value))}
            className="bg-default-100 rounded-3xl py-2 pl-2 bg-content1 text-default-500 cursor-pointer hover:bg-default-200"
          >
            {months.map((option) => (
              <option key={option} className="px-3 rounded-3xl bg-content1 border-none">
                {option}
              </option>
            ))}
          </select>

          <select
            value={dayjs(date).year().toString()}
            onChange={(e) => changeYear(e.target.value)}
            className="bg-default-100 rounded-3xl py-2 pl-2 bg-content1 text-default-500 cursor-pointer min-w-20 hover:bg-default-200"
          >
            {getYears(minDate, maxDate).map((option) => (
              <option key={option} className="px-3 rounded-3xl bg-content1 border-none">
                {option.toString()}
              </option>
            ))}
          </select>

          <Button
            isIconOnly
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            className="min-w-7 w-7 h-7 text-default-400"
            variant="light"
          >
            <KeyboardArrowRightIcon />
          </Button>
        </div>
      ),
      [minDate, maxDate],
    );

    return (
      <DatePicker
        selected={state}
        onChange={handleOnChange}
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
        peekNextMonth
        dateFormat={["MM/dd/yyyy", isDateTimePicker ? "HH:mm" : ""].filter(Boolean).join(" ")}
        timeFormat="HH:mm"
        wrapperClassName="old-w-100"
        customInput={
          <CustomInput
            onUpdate={handleOnChange}
            inputRef={ref}
            {...{ granularity, isDateTimePicker }}
            {...inputProps}
          />
        }
        calendarContainer={CustomContainer}
        renderCustomHeader={CustomHeader}
        maxDate={maxDate}
        minDate={minDate}
        showTimeSelect={isDateTimePicker}
        timeIntervals={5}
        name={name}
        popperPlacement="bottom"
      />
    );
  },
);
DatePickerInput.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  granularity: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};
DatePickerInput.defaultProps = {
  defaultValue: "",
  granularity: undefined,
  onChange: undefined,
  minDate: new Date(1920, 0, 1),
  maxDate: new Date(2099, 12, 0), // TODO: When it hits 2099 please update this :)
};

export default DatePickerInput;
