import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { Tabs, Tab, AutoScrollLoader } from "ui";
import { PageLayout } from "features/navigation";
import { LeaderboardList, useLeaderboard } from "features/leaderboard";

import CountryLeaderboardList from "./CountryLeaderboardList";
import CountrySwitch from "./CountrySwitch";

function LeaderboardScrollList({ leaderboardName }) {
  const leaderboard = useLeaderboard(leaderboardName);

  return (
    <>
      <LeaderboardList
        isLoading={leaderboard.isLoading}
        entries={leaderboard.all}
        scoreName={leaderboardName}
      />
      <AutoScrollLoader
        fetchNextPage={leaderboard.fetchNextPage}
        hasNextPage={leaderboard.hasNextPage}
        isLoading={leaderboard.isFetchingNextPage}
        error={leaderboard.error}
        empty={!leaderboard.all.length}
        className="mb-7"
      />
    </>
  );
}
LeaderboardScrollList.propTypes = {
  leaderboardName: PropTypes.string.isRequired,
};

export default function Leaderboard() {
  const [searchParams, setSearchParams] = useSearchParams();
  const isCountry = searchParams.get("c") === "true";
  const leaderboard = searchParams.get("b") ?? "briefs";

  return (
    <PageLayout>
      <div className="px-3 mb-20 max-w-[556px] mx-auto">
        <h2 className="uppercase text-center mt-2 mb-6">Oditi Leaderboard</h2>

        <div className="flex items-center gap-3 py-3 mb-2">
          <Tabs
            size="lg"
            radius="full"
            color="primary"
            fullWidth
            selectedKey={leaderboard}
            onSelectionChange={(key) =>
              setSearchParams((params) => {
                params.set("b", key);
                return params;
              })
            }
            defaultSelectedKey="briefs"
            classNames={{
              tab: "uppercase",
            }}
          >
            <Tab key="briefs" title="Briefs" />
            <Tab key="points" title="Points" />
          </Tabs>

          <CountrySwitch
            isSelected={isCountry}
            onValueChange={(value) =>
              setSearchParams((params) => {
                params.set("c", value);
                return params;
              })
            }
            size="lg"
          />
        </div>

        {isCountry ? (
          <CountryLeaderboardList leaderboardName={leaderboard} />
        ) : (
          <LeaderboardScrollList leaderboardName={leaderboard} />
        )}
      </div>
    </PageLayout>
  );
}
