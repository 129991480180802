import { useRef, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import Mixpanel from "adapters/mixpanel";
import { FeedbackContainer } from "features/feedback";
import { UserContext } from "features/user";
import { ReportModal } from "features/report";
import {
  SubmissionVoteButton,
  SubmissionActions,
  submissionPropTypes,
  useSubmissionReport,
} from "features/submission";
import { ShareButton, Shimmer, ModalClickContainer, Tabs, Tab, Chip } from "ui";

import SubmissionInsights from "./SubmissionInsights";

function SubmissionBodyLoading() {
  return (
    <div className="old-rounded-4 old-overflow-hidden">
      <Shimmer className="old-w-100 old-py-section-margin" />
    </div>
  );
}

export default function SubmissionBody({
  submission,
  allowSelectWinner = false,
  showVoteButton = true,
}) {
  const { user } = useContext(UserContext);
  const isOwnSubmission = user.id === submission.user.id;
  const feedbackRef = useRef(null);

  const handleScrollToFeedback = () => {
    if (!feedbackRef.current) return;
    feedbackRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const { hash } = useLocation();
  useEffect(() => {
    if (hash.length <= 0) return undefined;
    // timeout to wait for images to load before scrolling to feedback div
    const timeoutId = setTimeout(handleScrollToFeedback, 400);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="old-d-flex old-flex-row-reverse">
      <SubmissionActions
        scrollToFeedback={handleScrollToFeedback}
        submission={submission}
        allowSelectWinner={allowSelectWinner}
        showVoteButton={showVoteButton}
      />

      <div className="w-full rounded-3xl sm:overflow-hidden">
        <div className="bg-content1 block md:hidden old-p-2 flex justify-between items-center sticky top-0 old-z-3 shadow-2xl">
          <ShareButton
            title={`${submission.title} - entry to ${submission.brief.title} - by ${submission.user.username}`}
            trackingLocation="submission body"
            url={window.location.pathname}
            direction="horizontal"
            color="primary"
            tooltipProps={{
              placement: "bottom",
            }}
          />

          {showVoteButton && (
            <SubmissionVoteButton trackingLocation="submission body" submissionId={submission.id} />
          )}
        </div>

        <div className="bg-content1 text-foreground flex flex-col">
          {submission.images
            .sort((a, b) => a.order_id - b.order_id)
            .map((image) => image.image.compressed || image.image.original)
            .map((file) => (
              <img
                key={file.id}
                src={file.url}
                className="old-w-100"
                data-testid="submission-file"
                alt={file.name}
              />
            ))}

          <section className="text-center py-8 px-4 mb-0">
            <h4>Description</h4>
            <p className="old-description mb-2">{submission.description}</p>
            <p className="old-text-lightgrey">
              Published: {dayjs(submission.created_at).format("MMMM DD, YYYY")}
            </p>
          </section>

          <section
            ref={feedbackRef}
            className="bg-default-200 rounded-t-3xl mb-0 -mt-6 px-0 sm:px-6 pt-4 sm:pt-6 pb-6"
          >
            {isOwnSubmission ? (
              <Tabs
                color="primary"
                size="lg"
                radius="md"
                fullWidth
                defaultSelectedKey="feedback"
                disableAnimation
                onSelectionChange={(tab) => Mixpanel.trackButton("Submission Details", tab)}
                classNames={{
                  base: "px-4 sm:px-0",
                  tabList: "bg-transparent px-0",
                  tab: "bg-white py-6 rounded-xl data-[hover-unselected=true]:opacity-100",
                  cursor: "rounded-xl",
                  tabContent: "uppercase text-primary-100",
                  panel: "px-0",
                }}
              >
                <Tab key="feedback" title="Feedback">
                  <FeedbackContainer submissionId={submission.id} />
                </Tab>

                <Tab
                  key="insights"
                  title={
                    <div className="flex items-center">
                      Insights
                      <Chip size="sm" radius="sm" color="danger" className="ms-2">
                        NEW
                      </Chip>
                    </div>
                  }
                >
                  <SubmissionInsights submission={submission} />
                </Tab>
              </Tabs>
            ) : (
              <FeedbackContainer submissionId={submission.id} />
            )}
          </section>

          <section className="bg-default-200 mb-0 p-8 -mt-6">
            <div className="flex justify-around w-full mb-0 old-text-lightgrey font-roman">
              <small>&copy; All Rights Reserved</small>

              <ModalClickContainer
                modal={ReportModal}
                protectedAction
                modalProps={{
                  title: "Report Submission",
                  mutateFn: () => useSubmissionReport(submission.id),
                }}
                as="small"
              >
                Report
              </ModalClickContainer>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

SubmissionBody.propTypes = {
  allowSelectWinner: PropTypes.bool,
  showVoteButton: PropTypes.bool,
  submission: submissionPropTypes.isRequired,
};

SubmissionBody.Loading = SubmissionBodyLoading;
